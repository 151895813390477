import BookmarkIcon from '@mui/icons-material/Bookmark';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  Dashboard,
  DashboardListQuery,
  DashboardListResponse,
  DashboardOrderQuery,
} from '../../interfaces/Dashboard';
import {DraggableOrderCallbackResponse} from '../../interfaces/DataGrid';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {makeViewableDashboardList} from '../../utils/dashboard';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import DashboardItemActionsButton from './DashboardItemActionsButton';

const product_options: {
  id: number;
  value:
    | 'proximity'
    | 'ams'
    | 'belt'
    | 'connect'
    | 'shaft_clearence'
    | 'ventilation'
    | 'hazard_ai';
  name: string;
}[] = [
  {
    id: 0,
    value: 'proximity',
    name: 'Proximity',
  },
  {
    id: 1,
    value: 'ams',
    name: 'Gas Monitoring',
  },
  {
    id: 2,
    value: 'belt',
    name: 'Belt Monitoring',
  },
  {
    id: 3,
    value: 'connect',
    name: 'Connect',
  },
  {
    id: 4,
    value: 'shaft_clearence',
    name: 'Shaft Clearence',
  },
  {
    id: 5,
    value: 'ventilation',
    name: 'Ventilation',
  },
  {
    id: 6,
    value: 'hazard_ai',
    name: 'Hazard AI',
  },
];

interface Props {}

export interface DashbboardListRef {
  fetch?: () => void;
}

const DashbboardList = forwardRef<DashbboardListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
  }));
  const me = useAppSelector(({app}) => app.me);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const company = useAppSelector(({assets}) => assets.company);
  /*********/
  /* fetch */
  /*********/
  const dispatch = useDispatch();
  const dashboardList = useAppSelector(({assets}) => assets.dashboardList);
  const [fetchedData, setFetchedData] = useState<DashboardListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const fetchData = async (params: DashboardListQuery) => {
    setFetchedErrors([]);
    setFetchedInProgress(true);
    try {
      const resp = await API.get<DashboardListResponse>(
        `${apiBaseUrl}/dashboard`,
        {params}
      );
      setFetchedData(resp.data);
      reduxActions.assets.setAssets(dispatch, {dashboardList: resp.data.items});
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const rows = makeViewableDashboardList(me, company, dashboardList) ?? [];

  const columns: DataGridColumn<Dashboard>[] = [
    {
      field: 'draggable',
      headerName: 'Order',
      sortable: false,
      width: 120,
      renderCell: ({row}) =>
        row.is_favourite ? (
          <Button
            className="entity-drag-button"
            sx={{ml: 1, minWidth: 'auto', p: 1}}
          >
            <DragIndicatorIcon fontSize="small" />
          </Button>
        ) : null,
    },
    {
      field: 'name',
      sortable: true,
      renderCell: ({row}) => (
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            component={RouterLink}
            color="primary"
            fontSize={18}
            sx={{textDecoration: 'none'}}
            to={`/dashboards/${row.id}`}
          >
            {row.name}
          </Typography>
          {row.is_primary ? <BookmarkIcon color="warning" /> : null}
          {row.is_favourite ? <StarIcon color="info" /> : null}
        </Box>
      ),
    },
    {
      field: 'Product',
      sortable: true,
      renderCell: ({row}) =>
        row?.product
          ? product_options?.find((it) => it.value === row?.product)?.name ??
            '-'
          : '-',
    },
    {
      field: 'user_id',
      headerName: 'Owner',
      sortable: false,
      renderCell: ({row}) => (
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            component={RouterLink}
            color="primary"
            fontSize={18}
            sx={{textDecoration: 'none'}}
            to={`/dashboards/${row.id}`}
          >
            {assets?.users?.find((it) => it?.id === row.user_id)?.name ?? ''}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'created_at',
      sortable: false,
      headerName: 'Created at',
      renderCell: ({row}) => {
        return (
          <Box display="flex" alignItems="center">
            {row.created_at ? dayjs(row.created_at).format('YYYY-MM-DD hh:mm') : '-'}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 150,
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => {
        return (
          <Box display="flex" gap={1} justifyContent="end">
            <DashboardItemActionsButton
              item={row}
              component={IconButton}
              onSubmitted={() => fetchData(formik.values)}
              onDeleted={() => fetchData(formik.values)}
              isFetching={fetchedInProgress}
            >
              <MoreHorizIcon />
            </DashboardItemActionsButton>
          </Box>
        );
      },
    },
  ];

  const formik = useFormik<DashboardListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'DESC',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  const handleReorder = async (data: DraggableOrderCallbackResponse) => {
    const params: DashboardOrderQuery = {
      order: data,
    };
    setFetchedInProgress(true);
    try {
      await API.patch(`${apiBaseUrl}/dashboard/favourite/order`, params);
      fetchData(formik.values);
      reduxDispatch(reduxActions.app.fetchMe);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  return (
    <Paper sx={{position: 'relative', overflow: 'hidden', height: '100%'}}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <DataGrid
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        pagination
        paginationMode="server"
        size="small"
        sortBy={{
          field: formik.values.order,
          dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
        }}
        sortingMode="server"
        page={formik.values.page}
        pageSize={formik.values.limit}
        rowCount={fetchedData?.count}
        onPageChange={(v) => formik.setFieldValue('page', v)}
        onPageSizeChange={(v) => {
          formik.setFieldValue('limit', v);
          formik.setFieldValue('page', 0);
        }}
        onSort={(v) => {
          if (v) {
            formik.setFieldValue('order', v.field);
            formik.setFieldValue('dir', v.dir.toUpperCase());
          }
        }}
        draggable={true}
        draggableField="is_favourite"
        draggableClass=".entity-drag-button"
        draggableCallback={handleReorder}
      />
    </Paper>
  );
});

export default DashbboardList;
